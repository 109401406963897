<template>
  <StartPage />
</template>

<script>
import StartPage from './components/StartPage.vue';

export default {
  name: 'App',
  components: {
    StartPage
  }
};
</script>

<style>
/* Add global styles if needed */
</style>
