<template>
  <div class="container">
    <img src="@/assets/logo.png" alt="Logo" class="logo" />
    <div class="buttons">
      <a href="https://student-maker.com/privacy_policy.html" class="text-button">개인정보처리방침</a>
      <a href="https://student-maker.com/terms.html" class="text-button">이용약관</a>
      <a href="https://www.notion.so/a6f162f2ad044761807e8437a963c847?pvs=4" class="text-button">확률형 아이템 정보</a>
    </div>
    <!-- 새로운 이미지 버튼 컨테이너 추가 -->
    <div class="image-buttons">
      <!-- Google Play 버튼 -->
      <a href="https://play.google.com/store/apps/details?id=com.yeopcha.studentmaker">
        <img src="@/assets/google_play_black.png" alt="Google Play" />
      </a>

      <!-- App Store 버튼 -->
      <a href="https://apps.apple.com/kr/app/%EC%88%98%ED%97%98%EC%83%9D-%ED%82%A4%EC%9A%B0%EA%B8%B0-%EB%8C%80%ED%95%99-%EC%9E%85%EC%8B%9C-%EC%88%98%EB%8A%A5-%EC%8B%9C%EB%AE%AC%EB%A0%88%EC%9D%B4%EC%85%98-%EA%B2%8C%EC%9E%84/id6451279326">
        <img src="@/assets/app_store_black.png" alt="App Store" />
      </a>
    </div>
    <div class="footer">
      회사명 : 바삭한소프트 | 주소 : 서울시 성동구 왕십리로 222, 한양대학교 코맥스스타트업타운 1층 유니콘룸 | 사업자등록번호 : 306-09-10281 <br>
      통신판매업신고번호: 제 2024-서울성동-0973 호 | 문의 : official@basakansoft.com
    </div>
  </div>
</template>

<script>
export default {
  name: "StartPage",
};
</script>

<style scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #FFFAE9;
  position: relative; /* This allows us to position the footer at the bottom */
}

.logo {
  margin-bottom: 20px;
  width: 250px;
}

.text-button {
  margin: 5px;
  color: #4D2600;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

a {
  font-family: 'Roboto', sans-serif;
}

.buttons {
  margin-bottom: 20px; /* 버튼들과 이미지 버튼 사이의 여백 추가 */
}

.image-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.image-buttons a img {
  margin: 5px;
  width: 150px; /* 이미지 크기 조절 */
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  color: gray;
  font-size: 14px; /* You can adjust this value to make the text bigger or smaller */
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

</style>
